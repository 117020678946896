<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro
        class="mb-2"
        :name="owner.firstName"
        :text="
          $t('emails.ownerGuestsReport.intro', locale, {
            clientName: client.fullName,
            localizator: booking.localizator,
            accommodationName: accommodation.name,
          })
        "
      />

      <!-- PICTURE && ACCOMMODATION INFO -->
      <accommodation-info
        :accommodation="accommodation"
        booking-status="CONFIRMED"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- CHECK-IN AND CHECKOUT TIMES -->
      <checkin-checkout
        :checkin="checkinDay"
        :checkout="checkoutDay"
        :locale="locale"
        hide-times
      />

      <b-divider size="lg" />

      <!-- LOCALIZATOR -->
      <localizator :locale="locale" />

      <b-divider size="lg" />

      <!-- GUESTS -->
      <guests-description
        :alert-text="$t('emails.common.attachedGuestsReport', locale)"
        :locale="locale"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";
import AccommodationInfo from "@/views/bookings/booking/components/mail/AccommodationInfo.vue";
import CheckinCheckout from "@/views/bookings/booking/components/mail/CheckinCheckout.vue";
import GuestsDescription from "@/views/bookings/booking/components/mail/GuestsDescription.vue";
import Localizator from "@/views/bookings/booking/components/mail/Localizator.vue";
import { formatDateStringToDate } from "@/utils/formatters";
import {
  getAccommodationTypeName,
  getAdultsText,
  getBabiesText,
  getChildrensText,
} from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BDivider,
    Intro,
    AccommodationInfo,
    CheckinCheckout,
    GuestsDescription,
    Localizator,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationPicture() {
      return this.accommodation.coverPicture || null;
    },
    accommodationType() {
      if (!this.accommodation) return null;
      return getAccommodationTypeName(this.accommodation.type, this.locale);
    },
    accommodationLocation() {
      if (!this.accommodation?.location) return null;
      const location = [];
      if (this.accommodation.location.city)
        location.push(this.accommodation.location.city);
      if (this.accommodation.location.region)
        location.push(this.accommodation.location.region);
      if (!location.length) return null;
      return location.join(", ");
    },
    owner() {
      return this.$store.getters["booking/owner"];
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    checkinDay() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkin,
        this.locale,
        formatting
      );
    },
    checkoutDay() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkout,
        this.locale,
        formatting
      );
    },
    checkin() {
      return formatDateStringToDate(this.booking.checkin, this.locale);
    },
    checkout() {
      return formatDateStringToDate(this.booking.checkout, this.locale);
    },
    guests() {
      return this.$store.getters["booking/guests"];
    },
    adultsText() {
      return getAdultsText(this.booking.adults, this.locale);
    },
    adultsAgesText() {
      if (!this.guests.length) return this.$t("Adults", this.locale);
      const text = [];
      this.guests.forEach((guest) => {
        text.push(guest.age);
      });
      return `${text.join(", ")} ${this.$t(
        "AnysEdat",
        this.locale
      ).toLowerCase()}`;
    },
    childrenText() {
      return getChildrensText(this.booking.children, this.locale);
    },
    babiesText() {
      return getBabiesText(this.booking.babies, this.locale);
    },
    reportableGuests() {
      return this.$store.getters["booking/reportableGuests"];
    },
    formattedReportableGuests() {
      // Format the dates so they are displayed correctly in the generated pdf
      if (!this.reportableGuests.length) return [];
      return this.reportableGuests.map((guest) => ({
        ...guest,
        birthDate: formatDateStringToDate(guest.birthDate),
        idIssueDate: formatDateStringToDate(guest.idIssueDate),
      }));
    },
    subject() {
      return `${this.$t("emails.ownerGuestsReport.subject", this.locale, {
        localizator: this.booking.localizator,
      })}`;
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        ownerEmail: this.owner?.email || null,
        ownerSecondaryEmail: this.owner?.secondaryEmail || null,
        ownerName: this.owner?.firstName || null,
        clientName: this.client?.firstName || null,
        accommodationPicture: this.accommodationPicture?.url || null,
        accommodationDescription: this.accommodationType,
        accommodationName: this.accommodation?.name || null,
        accommodationLocation: this.accommodationLocation,
        accommodationCity: this.accommodation?.location?.city || null,
        accommodationRegion: this.accommodation?.location?.region || null,
        checkin: this.checkin,
        checkout: this.checkout,
        checkinDay: this.checkinDay,
        checkoutDay: this.checkoutDay,
        adults: this.booking?.adults || null,
        adultsAges: this.adultsAgesText || null,
        children: this.booking?.children || 0,
        babies: this.booking?.babies || null,
        localizator: this.booking?.localizator || null,
        reportableGuests: this.reportableGuests.length
          ? this.formattedReportableGuests
          : null,
      };
    },
  },
};
</script>
